import React, { Component } from 'react';
import Header from '../partials/header';
import Footer from '../partials/footer';
import {Accordion, Card, Button, Modal} from 'react-bootstrap';

class FAQ extends Component {
    state = {
        showSample: false,
    }
    gotoSignUp = (e) => {
        e.preventDefault()
        window.open(
            "/sign-up",
            '_blank'
        );
    }
    gotoSampleBillModal = (e) => {
        try{
            e.preventDefault()
        }catch(e){

        }
       
        // window.open(
        //     "/sign-up",
        //     '_blank'
        // );
        // localStorage.setItem("openSampleBill", true)
        this.setState({
            showSample: !this.state.showSample
        })
    }
    gotoForgotPass = (e) => {
        e.preventDefault()
        localStorage.clear()
        window.open(
            "/",
            '_blank'
        );
        localStorage.setItem("openForgotPass", true)
    }
    gotoForgotUser = (e) => {
        e.preventDefault()
        localStorage.clear()
        window.open(
            "/",
            '_blank'
        );
        localStorage.setItem("openForgotUser", true)
    }
    gotoContactInfo = (e) => {
        e.preventDefault()
        window.location.hash = '#contact-info';
    }
    gotoPayNow = (e) => {
        e.preventDefault()
        window.open(
            "/paynow",
            '_blank'
        );
    }
    render() { 
        return ( 
            <React.Fragment>
                <Header />
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h4 className="secondary-font-color">Frequently Asked Questions</h4>
                            </div>
                        </div>
                    </div>
                </section>
                <Modal show={this.state.showSample} onHide={this.gotoSampleBillModal.bind(this)} dialogClassName="hint-modal" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        <div className="hint">
                            <img src={process.env.PUBLIC_URL + '/gwa-bill-preview.png'} alt="find-account-number"/>
                        </div>
                    </Modal.Body>
                </Modal>
                <section id="faq">
                    <div className="container">
                        <div className="row">
                        {/* <div className="col-lg-12">
                                <Accordion>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                            Why is GWA changing their online payment system?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            To give our customers the best experience, we have improved PayGWA.com to be more user-friendly, as well as provide more helpful features that let you:<br/><br/>

                                            o Pay online 24/7<br/>
                                            o Make quick and easy payments with our new PAY NOW — all you need is your account number and billing zip code<br/>
                                            o Monitor monthly water usage<br/>
                                            o View all monthly bills<br/>
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div> */}
                            <div className="col-lg-12">
                                <h5>User Registration</h5>
                            </div>
                            <div className="col-lg-12">
                                <Accordion>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="5">
                                            Why is GWA changing their online payment system?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="5">
                                        <Card.Body>
                                            To give our customers the best experience, we have improved PayGWA.com to be more user-friendly, as well as provide more helpful features that let you:<br/><br/>

                                            o Pay online 24/7<br/>
                                            o Make quick and easy payments with our new PAY NOW — all you need is your account number and GWA billing zip code<br/>
                                            o Monitor monthly water usage<br/>
                                            o View all monthly bills<br/>
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                            I already pay my bill using PayGWA.com — can I just log in as I usually do?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            {/* To access the new PayGWA, you will need to <a href="/" onClick={this.gotoSignUp.bind(this)}>re-register</a>. However, you can use your existing PayGPWA mobile app username and password. */}
                                            With our new system, all current PayGWA users will first need to choose “<a href="/" onClick={this.gotoSignUp.bind(this)}>Register Here</a>” to log into the new payment system. This is a one-time step, and only takes a couple of minutes!
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                            Will my current PayGWA username and password work for the new PayGWA?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                            In order to use your current PayGWA credentials, you will first need to re-register by choosing the “<a href="/" onClick={this.gotoSignUp.bind(this)}>Register Here</a>” option.
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                            What do I need to register for the new PayGWA?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="2">
                                        <Card.Body>
                                            All you need is your 10-digit GWA Account Number and your GWA billing zip code. Here’s where you can find it on your <a href="/" onClick={this.gotoSampleBillModal.bind(this)}>GWA bill</a>. 
                                           
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    {/* <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                            I have a PayGPWA mobile app user name and password, can I use that?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="3">
                                        <Card.Body>Yes, your existing PayGPWA mobile app user name and password will work in the new PayGWA.</Card.Body>
                                        </Accordion.Collapse>
                                    </Card> */}
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                            Can I pay without registering to the new PayGWA?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="4">
                                        <Card.Body>
                                            Yes! For your convenience, the new PayGWA site has a quick and easy feature called <a href="/paynow" target="_blank">Pay Now</a>. Just enter your GWA Account Number and GWA billing zip code, confirm your details and enter your VISA, MasterCard or Discover credit card information to issue payment.
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                            <div className="col-lg-12">
                                <h5>Logging In</h5>
                            </div>
                            <div className="col-lg-12">
                                <Accordion>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                            When I attempt to re-register, an error reads: “This account is already registered for PayGWA.” What should I do?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            If you receive this error, you may already have an account active in the PayGWA system. Choose the “<a href="/" onClick={this.gotoForgotUser.bind(this)}>Forgot Username</a>” and/or “<a href="/" onClick={this.gotoForgotPass.bind(this)}>Forgot Password</a>” self-service option, which will send your login information to the email address attached to your account.
                                            {/* <a href="/" onClick={this.gotoContactInfo.bind(this)}>business hours</a> or email GWA at <a href="mailto:customers@guamwaterworks.org">customers@guamwaterworks.org</a>. You can also use the self service feature: . */}
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                            When I try to login, an error reads: “You have exceeded the maximum number of failed login attempts.” What should I do? 
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                            Please call GWA Customer Service at 647-7800 Monday-Friday from 8AM to 5PM, or email customers@guamwaterworks.org anytime, and one of our Customer Care team members will assist with resetting your account.
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    {/* <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                            I forgot my password
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                        <Card.Body>"You can use this self-service feature to retrieve your <a href="/" onClick={this.gotoForgotPass.bind(this)}>password</a>. The password will be sent to your registered email.</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                            I forgot my username
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="2">
                                        <Card.Body>You can use this self-service feature to retrieve your <a href="/" onClick={this.gotoForgotUser.bind(this)}>username</a>.</Card.Body>
                                        </Accordion.Collapse>
                                    </Card> */}
                                </Accordion>
                            </div>
                            <div className="col-lg-12">
                                <h5>Using PayGWA</h5>
                            </div>
                            <div className="col-lg-12">
                                <Accordion>
                                    {/* <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                            Can I pay without registering to the new PayGWA?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                        <Card.Body>Yes, all you need is your 10-digit account number, your billing zip code and a VISA, MasterCard or Discover credit card. You can do this by clicking <a href="/paynow" target="_blank">Pay Now</a> on the PayGWA login page.</Card.Body>
                                        </Accordion.Collapse>
                                    </Card> */}
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                            Previously, I have had multiple accounts linked to my profile, where are they now?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                        <Card.Body>For your peace of mind, GWA has made linked accounts more secure by verifying that any account linkage is authorized. Please call GWA Customer Service at 647-7800 Monday-Friday from 8AM to 5PM for assistance in linking accounts.</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    {/* <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                            What are the features of the new PayGWA?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="2">
                                        <Card.Body>
                                            <ul>
                                                <li>Register and see more account information such as the following: <br/> 
                                                    o Know your current balance <br/> 
                                                    o View a Bill <br/> 
                                                    o View Consumption <br/> 
                                                    o See Payments made through PayGWA <br/> 
                                                    o Edit basic contact information <br/> 
                                                    o Send a Secure Message <br/> 
                                                </li>
                                            </ul>
                                            <ul>
                                                <li>You can pay your GWA Account as a guest –simply know your 10-digit account number and billing zip code.</li>
                                            </ul>
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card> */}
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                            Is there a maximum payment amount limit when using PayGWA?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="3">
                                        <Card.Body>At this time, there is no limit for paying with VISA, MasterCard or Discover.</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                            <div className="col-lg-12">
                                <h5 id="contact-info">CONTACT US</h5>
                            </div>
                            <div className="col-lg-12">
                                <Accordion>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                            What are your business hours?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <label htmlFor="" className="font-bold">Gloria B. Nelson Public Service Building:</label>
                                            <br/>
                                            <label htmlFor="">Mon-Fri 7:00am-5:00pm</label>
                                            <br/>
                                            <label htmlFor="" className="font-bold">Upper Tumon Satellite Office:</label>
                                            <br/>
                                            <label htmlFor="">Mon-Fri 7:30am-6:00pm</label>
                                            <br/>
                                            <label htmlFor="" className="font-bold">Julale Satellite Office Hagatna:</label>
                                            <br/>
                                            <label htmlFor="">Mon-Fri 8:00am-5:00pm</label>
                                            <br/>
                                            <label htmlFor="">Our locations are closed on most GovGuam Holidays, see the full holiday schedule <a href="http://guamwaterworks.org/2020-observed-holidays/" rel="noopener" target="_blank">here</a>.</label>
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                            What is your email address?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                            <label htmlFor="">You can send us an email at <span className="font-bold"><a href="mailto:customers@guamwaterworks.org">customers@guamwaterworks.org</a></span> </label>
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                            <div className="col-lg-12">
                                <center><button id="go-back" type="button" class="btn btn-primary font-bold" onClick={()=>{window.location.replace("/")}}>Go Back</button></center>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}
export default FAQ;